export const timezoneOptions = {
  'US': [
    {name: 'Eastern', value: 'America/New_York', abbreviation: 'EST'},
    {name: 'Central', value: 'America/Chicago', abbreviation: 'CST'},
    {name: 'Mountain', value: 'America/Denver', abbreviation: 'MST'},
    {name: 'Pacific', value: 'America/Los_Angeles', abbreviation: 'PST'},
    {name: 'Alaska', value: 'America/Anchorage', abbreviation: 'AKST'},
    {name: 'Hawaii', value: 'Pacific/Honolulu', abbreviation: 'HST'}
  ],
  'CA': [
    {name: 'Eastern', value: 'America/Toronto', abbreviation: 'EST'},
    {name: 'Central', value: 'America/Winnipeg', abbreviation: 'CST'},
    {name: 'Mountain', value: 'America/Edmonton', abbreviation: 'MST'},
    {name: 'Pacific', value: 'America/Vancouver', abbreviation: 'PST'}
  ],
  'GH': [
    {name: 'Greenwich', value: 'Africa/Accra', abbreviation: 'GMT'}
  ],
  'BR': [
    {name: 'Brasilia', value: 'America/Sao_Paulo', abbreviation: 'BRT'}
  ]
  // key is what the code maps for the value that the user inputs - value is whatever text we want the end user to type to assign this key
}