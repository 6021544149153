import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Switch, Tooltip, Button, Fab } from "@material-ui/core";
import {makeStyles, withStyles} from '@material-ui/core/styles'
import ScheduleIcon from '@material-ui/icons/Schedule'
import CircularProgress from '@material-ui/core/CircularProgress';
import Program from '../services/program'
import {Link} from 'react-router-dom'
import MakeIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ReportIcon from '@material-ui/icons/TableChart'
import MessageIcon from '@material-ui/icons/Message'
import {green, grey} from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Notify from './Notify'
import auth from '../services/auth'


import ConfirmDeleteProgram from './ProgramsTable/ConfirmDeleteProgram'

const GreenSwitch = withStyles({
  switchBase: {
    color: grey[300],
    '&$checked': {
      color: green[500]
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    }
  },
  checked: {},
  track: {}
})(Switch)

const useStyles = makeStyles((theme) => (
  {
    statusActive: {
      color: '#4caf50'
    },
    statusInactive: {
      color: '#616161'
    },
    statusDisabled: {
      color: '#d32f2f'
    },
    newProgramFab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    }
  }
))

const ConfirmDefaultChange = function (props) {
  const [open] = React.useState(true);

  const decline = () => {
    props.onClose(false)
  }

  const agree = () => {
    props.onClose(true)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={decline}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            `Are you sure you want to ${props.program.isDefault ? 'unset' : 'set'} this program as default?`
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              props.currentProgram && props.currentProgram.id === props.program.id && (
                'By unsetting the default program, participants will not be assigned to any program upon completing their initial survey.'
              )
            }
            {
              props.currentProgram && props.currentProgram.id !== props.program.id && (
                `By changing the default program, all new participants will be assigned to the "${props.program.name}" program once they complete their initial survey.`
              )
            }
            {
              !props.currentProgram && (
                `All new participants will be assigned to the "${props.program.name}" program after they complete the initial survey.`
              )
            }
          </DialogContentText>
        </DialogContent>
          <DialogActions>
            <Button onClick={decline} color="primary">
              Cancel
            </Button>
            <Button onClick={agree} color="primary" autoFocus>
              Continue
            </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
}

const ConfirmStatusChange = function (props) {
  const [open] = React.useState(true);

  const decline = () => {
    props.onClose(false)
  }

  const agree = () => {
    props.onClose(true)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={decline}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            !props.disabled && (`Are you sure you want to ${props.active ? 'Deactivate' : 'Activate'} this program?`)
          }
          {
            props.disabled && ('Your organization status is inactive.')
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              props.active && !props.disabled && (
                'By de-activating this program, no new subscribers can join, existing subscribers will stop receiving their messages, and you cannot invite new subscribers.'
              )
            }
            {
              !props.active && !props.disabled && (
                'By activating this program, new subscribers can join, invites can be sent out, and existing subscribers will start receiving messages again.'
              )
            }
            {
              props.disabled && (
                `You cannot activate a program in an inactive organization. Please contact your organization's administrator for further information.`
              )
            }
          </DialogContentText>
        </DialogContent>
          {
            !props.disabled && (
              <DialogActions>
                <Button onClick={decline} color="primary">
                  Cancel
                </Button>
                <Button onClick={agree} color="primary" autoFocus>
                  Continue
                </Button>
              </DialogActions>
            )
          }
          {
            props.disabled && (
              <DialogActions>
                <Button onClick={decline} color="primary">
                  Close
                </Button>
              </DialogActions>
            )
          }
      </Dialog>
    </div>
  );
}

export default function ProgramsTable (props) {
  const classes = useStyles()
  const [programs, setPrograms] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  // const [count, setCount] = React.useState(0)
  // const {organization} = useAppState()
  // const [query, setQuery] = React.useState({})
  const [promptStatusChange, setPromptStatusChange] = React.useState({show: false, program: null})
  const [promptDefaultChange, setPromptDefaultChange] = React.useState({show: false, program: null})
  const [promptDeleteProgram, setPromptDeleteProgram] = React.useState({show: false, program: null})
  const [notify, setNotify] = React.useState({show: false})
  
  const onNotifyClose = function () {
    setNotify({show: false})
  }

  const headers = [
    {
      label: 'Name',
      key: 'name'
    },
    {
      label: 'Program Status',
      key: 'active'
    },
    // {
    //   label: 'Default Program',
    //   key: 'isDefault'
    // },
    {
      label: 'Organization Status',
      key: 'disabled'
    },
    {
      label: 'Next Deployment',
      key: 'nextRun'
    },
    {
      label: 'Message Count',
      key: 'stats_sent'
    },
    {
      label: 'Subscribers',
      key: 'subscribers'
    }
  ].filter(header => {
    return header.key !== 'isDefault' || (header.key === 'isDefault' && props.type === 'email')
  })

  // const loadPrograms = () => {
    
  // }

  React.useEffect(() => {
    if (props.organizationId) {
      if (!promptDeleteProgram.show) {
        setLoading(true)
        Program.get({organizationId: props.organizationId})
          .then(programsList => {
            setLoading(false)
            setPrograms(programsList)
          })
          .catch(err => {
            console.log('Programs.get.failed', err)
            setLoading(false)
          })
      }
    }
  }, [props.organizationId, promptDeleteProgram])

  const statusIcon = (status) => {
    switch (status) {
      case 'process':
        return <CircularProgress
          variant="indeterminate"
          disableShrink
          size={20}
          thickness={2}
        />
      case 'queue':
        return <ScheduleIcon />
      default:
        return null
    }
  }

  const format = (key, data) => {
    switch (key) {
      case 'subscribers':
        // have to filter out subscribed: false
        return data[key] ? Object.keys(data[key]).filter(id => data[key][id]).length : 0
      case 'stats_sent':
        return (data.stats || {}).sent || 0
      case 'nextRun':
        return data[key] ? new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'long'}).format(new Date(data[key])) : 'N/A'
      case 'disabled':
        return Boolean(data.disabled) ? 'Inactive' : 'Active'
      case 'isDefault':
        const isDefault = data.isDefault
        return <Tooltip title={isDefault ? 'Default' : 'Not Default'}>
          <GreenSwitch
            checked={isDefault}
            onChange={() => promptDefaultChangeModal(data)}
            name="active"
            inputProps={{ 'aria-label': 'Default program assignment for Participants' }}
          />
        </Tooltip>
      case 'active': {
        const isDisabled = data.disabled
        const isActive = data.active
        return <Tooltip title={isActive ? 'Active' : 'Inactive'}>
          <GreenSwitch
            checked={isActive && !isDisabled}
            onChange={() => promptStatusChangeModal(data)}
            name="active"
            inputProps={{ 'aria-label': 'Program Active State' }}
          />
        </Tooltip>

      }
      case 'status':
        return statusIcon(data[key])
      default:
        return data[key]
    }
  }

  const generateLink = function (id, path, queryOverride) {
    const orgId = props.organizationId
    const query = id === 'new' ? `?communication_method=${props.type}` : ''
    return `/${path || 'program'}/${auth.isRole('owner') ? `${orgId}/${id}` : id}${query}`
  }

  const toggleDefault = function (program) {
    const orgId = props.organizationId
    const {isDefault, id} = program

    Program.update({id, isDefault, organizationId: orgId})
      .then(() => {
        let _programs;
        
        _programs = programs.map(prog => {
          if (prog.isDefault && prog.id !== id) {
            return {...prog, isDefault: false}
          }

          if (prog.id === id) {
            return {...prog, isDefault}
          }

          return prog
        })

        setPrograms(_programs)
      })
  }

  const toggleActive = function (prog) {
    const orgId = props.organizationId
    const {active, id} = prog

    if (active) {
      return Program.deactivate(id, orgId)
        .then(() => {
          const _programs = programs.map(p => {
            if (p.id === id) {
              p.active = false
            }
            return p
          })

          setPrograms(_programs)
        })
    }

    return Program.activate(id, orgId)
      .then(() => {
        const _programs = programs.map(p => {
          if (p.id === id) {
            p.active = true
          }
          return p
        })

        setPrograms(_programs)
      })
  }

  const onConfirmStatusChange = function (confirm) {
    if (confirm) {
      toggleActive(promptStatusChange.program)
    }

    setPromptStatusChange({show: false, program: null})
  }

  const promptStatusChangeModal = function (program) {
    setPromptStatusChange({show: true, program})
  }

  const promptDefaultChangeModal = function (program) {
    // toggle is being toggled on
    let currentProgram = null
    if (program.isDefault) {
      // check if another program was default before this
      currentProgram = programs.find(prog => prog.isDefault)
    }
    setPromptDefaultChange({show: true, program, currentProgram})
  }

  const onConfirmDefaultChange = function (confirm) {
    if (confirm) {
      toggleDefault({...promptDefaultChange.program, isDefault: !promptDefaultChange.program.isDefault})
    }

    setPromptDefaultChange({show: false, program: null})
  }

  const promptDeleteProgramModal = function (program) {
    setPromptDeleteProgram({show: true, program})
  }

  const onConfirmDeleteProgram = function (confirm) {
    if (confirm) {
      Program.delete(promptDeleteProgram.program.id, props.organizationId)
        .then(() => {
          setPromptDeleteProgram({show: false, program: null})
          setNotify({show: true, variant: 'success', message: `Successfully deleted Program.`})
        })
        .catch(err => {
          setPromptDeleteProgram({show: false, program: null})
          setNotify({show: true, variant: 'error', message: `Failed to delete program, try again. if the issue persists contact an administrator.`})
        })
    }
  }

  if (loading) {
    return null
  }

  return (
    <React.Fragment>
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}
      <div>
        {promptStatusChange.show && <ConfirmStatusChange onClose={onConfirmStatusChange} disabled={promptStatusChange.program.disabled} active={promptStatusChange.program.active} />}
      </div>

      <div>
        {promptDefaultChange.show && <ConfirmDefaultChange onClose={onConfirmDefaultChange} currentProgram={promptDefaultChange.currentProgram} program={promptDefaultChange.program} />}
      </div>

      <div>
        {promptDeleteProgram.show && <ConfirmDeleteProgram onClose={onConfirmDeleteProgram} program={promptDeleteProgram.program} />}
      </div>
      {/* <Typography component="h2" variant="h6" color="primary" gutterBottom>
        My Programs
        <Link to={generateLink('new')}>
          <IconButton>
            <MakeIcon />
          </IconButton>
        </Link>
      </Typography> */}
      {/* <Typography variant="h5">My Programs</Typography> */}
      <TableContainer component={Paper} style={{borderTopLeftRadius: '0', borderTopRightRadius: '0', boxShadow: '1px 2px 2px 0px rgb(0 0 0 / 20%), 0px 4px 1px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'}}>
        <Table aria-label="Table of Programs">
          <TableHead>
            <TableRow>
              {headers.map(header => <TableCell key={header.key}>{header.label}</TableCell>)}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              programs
                .filter(program => program.communication_method === props.type)
                .map(row => (
                  <TableRow key={row.id}>
                    {headers.map(header => (
                      <TableCell key={`${row.id}_${header.key}`}>{format(header.key, row)}</TableCell>
                    ))}
                    <TableCell>
                      <Link to={generateLink(row.id)}>
                        <IconButton aria-label="Edit Program">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      
                      {
                        auth.hasFeature('message') ? (
                          <Link to={generateLink(row.id, 'adhocMessages', '')}>
                            <IconButton aria-label="Send adhoc messages">
                              <MessageIcon />
                            </IconButton>
                          </Link>
                        ) : null
                      }

                      <Link to={generateLink(row.id, 'report')}>
                        <IconButton aria-label="Program Stats">
                          <ReportIcon />
                        </IconButton>
                      </Link>
                      
                      {
                        auth.hasFeature('delete') ? (
                          <IconButton aria-label="Delete Program" disabled={row.isDefault} onClick={() => promptDeleteProgramModal(row)}>
                            <DeleteIcon />
                          </IconButton>
                        ) : null
                      }
                    </TableCell>
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Link to={location => generateLink('new')}>
        <Fab
            className={classes.newProgramFab}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
          <MakeIcon />
          New Program
        </Fab>
      </Link>
    </React.Fragment>
  )
}