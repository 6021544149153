import React from 'react'
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell
} from '@material-ui/core'

export default function SubscribersTable (props) {
  const {subscribers = []} = props

  console.log('subscribers', subscribers)

  const headers = [
    { key: 'id', 'label': 'ID' },
    { key: 'phone', 'label': 'Phone' },
    { key: 'email', 'label': 'Email' },
    { key: 'subscribed', 'label': 'Subscribed' },
    { key: 'timezone', 'label': 'Timezone' },
    { key: 'timeOfDay', 'label': 'Time of Day' },
    { key: 'nextRun', 'label': 'Next Scheduled Run' },
    { key: 'completed', 'label': 'Completion Count'}
  ]

  const timeOfDayMappings = {
    'morning': 'Morning (9:00AM)',
    'afternoon': 'Afternoon (12:00PM)',
    'evening': 'Evening (5:00PM)'
  }

  const formatValue = (key, value) => {
    switch (key) {
      case 'subscribed':
        return value ? 'Yes' : 'No'
      case 'nextRun':
        return value ? new Date(value).toLocaleString() : 'N/A'
      case 'timeOfDay':
        return timeOfDayMappings[value] || value
      default:
        return value
    }
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" gutterBottom>
        Subscribers Table
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Table of Participants">
          <TableHead>
            <TableRow>
              {headers.map(header => <TableCell key={header.key}>{header.label}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {subscribers.map(row => (
              <TableRow key={row.id}>
                {headers.map(header => (
                  <TableCell key={`${row.id}_${header.key}`}>{formatValue(header.key, row[header.key])}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}