import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TextField,
  FormHelperText,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Checkbox
} from '@material-ui/core'
import Program from '../services/program'
import { Alert, AlertTitle } from '@material-ui/lab'
import MakeIcon from '@material-ui/icons/Add'
import ImportIcon from '@material-ui/icons/ImportContacts'
import PendingIcon from '@material-ui/icons/HourglassEmpty'
import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Error'
import MakeMessage from './MakeMessage'
import InviteSubscriber from './InviteSubscriber'
import SubscribersList from './SubscribersList'
import ImportSubscribers from './ImportSubscribers'
import MessagesList from './MessagesList'
import EmailMessagesList from './EmailMessagesList'
import ImportMessages from './ImportMessages'
import SurveySelector from './Surveys/SurveySelector'
import Auth from '../services/auth'
import axios from 'axios'
import ChangeSubscriberSubscriptionStatus from './ChangeSubscriberSubscriptionStatus'
import ProgramTimeScheduler from './ProgramTimeScheduler'
import PublicProgramLink from './PublicProgramLink'
import PublicFieldsProgramForm from './PublicFieldsProgramForm'
import SubscribersTable from './SubscribersTable'
import { timezoneOptions } from '../utils/userPreferenceSettings'

const LoadingDialog = function (props) {
  const {load, update} = props

  const handleClose = () => {

  }

  const renderByState = (state) => {
    switch (state) {
      case 'queue':
        return <PendingIcon />
      case 'process':
        return <CircularProgress size={22} />
      case 'done':
        return <DoneIcon />
      case 'fail':
        return <ErrorIcon />
      default:
        return null
    }
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="Setting up Program"
    >
      <DialogTitle>{update ? 'Updating your Program' : 'Setting up your Program'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please wait while we set up your program.

          <Typography>
            {
              load.program && (
                <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '160px'}}>{renderByState(load.program)} {update ? 'Updating Program' : 'Creating Program'}</span>
              )
            }
          </Typography>

          <Typography>
            {
              load.message && (
                <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '182px'}}>{renderByState(load.message)} Importing Messages</span>
              )
            }
          </Typography>
          
          <Typography>
            {
              load.replies && (
                <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '160px'}}>{renderByState(load.replies)} Importing Replies</span>
              )
            }
          </Typography>
          <Typography>
            {
              load.subs && (
                <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '193px'}} >{renderByState(load.subs)} Importing Subscribers</span>
              )
            }
          </Typography>
          <Typography>
            {
              load.preload && (
                <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '193px'}} >{renderByState(load.preload)} Preparing Update</span>
              )
            }
          </Typography>
        </DialogContentText>
      </DialogContent>
      {/* <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

export default function ProgramForm(props) {
  const [state, setState] = React.useState({
    name: '',
    interval: '0 9 * * *',
    timezone: null,
    disabled: false,
    isPublic: false,
    active: true,
    skipInvite: false,
    surveyMandatory: false,
    description: '',
    image: null,
    imageSrc: null,
    programAssets: [],
    communication_method: props.communicationMethod || 'sms',
    features: ['message', 'subscriber', 'reply'],
    supportedCommsMethods: [],
    surveyParticipation: true,
    surveyId: null,
    messages: [],
    excludeProgramNameInMessage: false,
    replies: [
      {
        type: 'reply',
        event: 'invite',
        prepend: `You have been invited.`,
        excludePrepend: false,
        body: ''
      },
      {
        type: 'reply',
        event: 'subscribe',
        prepend: `You have been subscribed.`,
        excludePrepend: false,
        body: ''
      },
      {
        type: 'reply',
        event: 'unsubscribe',
        prepend: `You have been unsubscribed.`,
        excludePrepend: false,
        body: ''
      }
    ],
    contacts: [],
    newMessage: {index: 1}
  })
  const [showMakeMessage, setShowMakeMessage] = React.useState(false)
  const [showEditMessage, setShowEditMessage] = React.useState(false)
  const [showInviteSubscriber, setShowInviteSubscriber] = React.useState(false)
  const [showImportSubscribers, setShowImportSubscribers] = React.useState(false)
  const [showImportMessages, setShowImportMessages] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [updatingSubscribers, setUpdatingSubscribers] = React.useState(false)
  const [updateSubscriberStatus, setUpdateSubscriberStatus] = React.useState({show: false})
  const [loadState, setLoadState] = React.useState({program: false, message: false, replies: false, subs: false})
  const [error, setError] = React.useState(null)
  const [initLoad, setInitLoad] = React.useState(true)
  
  // flag to call deleteAllMessages api, because csv import is to delete all existing messages and import new file
  // only applicable to updates
  const [deleteAllMessages, setDeleteAllMessages] = React.useState(false)

  const getTimezoneOptionsByCC = (cc) => {
    return (timezoneOptions[cc] || []).map(tz => ({ responseKey: tz.abbreviation, label: tz.abbreviation, value: tz.value }))
  }

  React.useEffect(() => {
    const initDefaults = {...state, supportedCommsMethods: props.communicationMethods}

    if (initDefaults.supportedCommsMethods.length === 1) {
      if (props.communicationMethod && initDefaults.supportedCommsMethods.includes(props.communicationMethod)) {
        initDefaults.communication_method = props.communicationMethod
      } else {
        initDefaults.communication_method = initDefaults.supportedCommsMethods[0]
      }
    }
    
    if (props.program) {
      const {
        id,
        name,
        imageSrc = null,
        communication_method = 'sms',
        surveyParticipation = false,
        programAssets = [],
        description = '',
        keyword,
        timezone,
        interval,
        disabled,
        isPublic,
        active,
        surveyId,
        skipInvite = false,
        excludeProgramNameInMessage = false,
        subscriberSetInterval = false,
        preRequisites = null
      } = props.program
      
      let newState = {
        ...state,
        id,
        name,
        description,
        programAssets,
        keyword,
        timezone,
        imageSrc,
        interval,
        disabled,
        isPublic,
        active,
        skipInvite,
        surveyParticipation,
        surveyId,
        excludeProgramNameInMessage,
        subscriberSetInterval,
        surveyMandatory: false
      }

      newState.supportedCommsMethods = props.communicationMethods

      newState.communication_method = communication_method

      if (!props.communicationMethods.includes(communication_method)) {
        newState.supportedCommsMethods = [communication_method]
      }
      if (props.messages) {
        newState.messages = props.messages
      }

      if (props.contacts) {
        newState.contacts = props.contacts.map(c => ({...c, valid: true, registered: true})) // trust they're valid
      }

      if (props.replies) {
        newState.replies = updateReplyPrepends(name, keyword, props.replies.map(r => ({...r, type: 'reply'})))
      }

      if (newState.subscriberSetInterval) {
        newState.interval = 'none'
      }

      if (preRequisites) {
        newState.surveyMandatory = true
      }

      setState({...newState, features: getFeatures(newState.communication_method)})
      setInitLoad(false)
    } else {
      setState({...initDefaults, features: getFeatures(initDefaults.communication_method), ...getDefaultMessagesState(), ...getDefaultContactsState(), ...getDefaultRepliesState(initDefaults.communication_method, initDefaults.interval)})
      setInitLoad(false)
    }
  }, [])

  const getDefaultMessagesState = function () {
    return {messages: [], newMessage: {index: 1}}
  }

  const getDefaultContactsState = function () {
    return {contacts: []}
  }

  const getDefaultRepliesState = function (method, interval) {
    if (method === 'sms') {
      return {
        replies: [
          {
            type: 'reply',
            event: 'invite',
            prepend: `You have been invited.`,
            excludePrepend: false,
            body: ''
          },
          {
            type: 'reply',
            event: 'subscribe',
            prepend: `You have been subscribed.`,
            excludePrepend: false,
            body: ''
          },
          {
            type: 'reply',
            event: 'unsubscribe',
            prepend: `You have been unsubscribed.`,
            excludePrepend: false,
            body: ''
          },
          {
            type: 'reply',
            event: 'cancel',
            prepend: `You have cancelled the subscription process. If you would like to subscribe again to this program, please reply with the keyword.`,
            excludePrepend: false,
            body: ''
          },
          {
            type: 'reply',
            event: 'notify_program_questionnaire',
            prepend: `In order to subscribe, you will need to answer a few questions. Please respond with OK to continue. Respond with CANCEL at anytime to stop the process.`,
            excludePrepend: false,
            body: ''
          },
          {
            type: 'reply',
            event: 'invalid_answer',
            prepend: 'Invalid response. Please reply with one of the following options:',
            excludePrepend: false,
            body: ''
          },
          {
            type: 'reply',
            event: 'ask_timeofday',
            prepend: 'What time of day would you like to receive messages? Please reply with one of the following options:',
            excludePrepend: false,
            body: '',
            options: [
              {
                label: 'Morning',
                responseKey: 'Morning',
                value: '0 9 * * *'
              },
              {
                label: 'Afternoon',
                responseKey: 'Afternoon',
                value: '0 12 * * *'
              },
              {
                label: 'Evening',
                responseKey: 'Evening',
                value: '0 18 * * *'
              }
            ]
          },
          {
            type: 'reply',
            event: 'ask_timezone',
            prepend: 'What is your timezone? Please reply with one of the following options:',
            excludePrepend: false,
            body: '',
            options: getTimezoneOptionsByCC(props.countryCode)
          },
        ]
      }
    } else {
      return {replies: []}
    }
  }

  // const onClose = function () {
  //   props.onClose()
  // }

  const onUpdate = function () {
    setError(null)
    setLoading(true)
    setLoadState({...loadState, program: 'process'})

    let preRequests = []
    let requests = []
    let postRequests = [] // for assets(images) uploads for messages

    // preRequests will go here
    // these need to happen before writing new data can happen. Such as, deleting all existing messages before writing new ones.

    if (Auth.hasFeature('message')) {
      if (deleteAllMessages) {
        preRequests.push(Program.deleteAllMessages({organizationId: props.organizationId, programId: state.id}))
        setLoadState({...loadState, preload: 'process'})
      }
    }

    Promise.all(preRequests)
      .then(() => {
        setLoadState({...loadState, preload: 'done'})
        if (Auth.hasFeature('message') && state.messages.length) {
          let newMessages = []

          // console.log('checkMessages', props.messages, state.messages)
          
          // if its a new import, then deleteAllMessages is true and we don't check for modifications or additions
          if (!deleteAllMessages) {
            // only modified or new additions will be sent for update
            // const newMessages = state.messages.filter(msg => msg.new)
            const modified = props.messages
              .map((msg, i) => {

                // its an existing message
                if (msg.id) {
                  const update = state.messages.find(m => m.id === msg.id)
                  // console.log('check for deletion', msg.id, state.messages.find(m => m.id === msg.id))
                  // if no longer exists, flag to remove it
                  if (!update) {
                    return {...msg, remove: true}
                  }
                  
                  // if has changed, then return to update it
                  if (update.new) {
                    return update
                  }

                  // if no modifications, return null to skip it
                  return null
                }

                // if its a new message, should be returned to add
                if (state.messages[i] && state.messages[i].new) {
                  return state.messages[i]
                }
      
                // skip if no conditions matched
                return null
              })
              .filter(msg => msg)
      
            const additions = state.messages.filter(msg => msg.new && !msg.id)
      
            // console.log('newMessages', modified, additions, [...modified, ...additions])
            newMessages = [...modified, ...additions]
          } else {
            newMessages = state.messages
          }

          // console.log('newMessages', newMessages)
          
          if (newMessages.length) {
            setLoadState({...loadState, message: 'process'})
            requests.push(
              Program.importMessages({programId: state.id, organizationId: props.organizationId, messages: newMessages})
                .then(r => {
                  if (r.messageIds && r.messageIds.length) {
                    // uses order based on the index value
                    for (let i = 0; i < r.messageIds.length; i++) {
                      const messageRequest = r.messageIds[i]
                      const message = state.messages[messageRequest.index - 1]

                      if (messageRequest.upload && messageRequest.upload.length) {
                        postRequests.push(
                          axios
                            .put(messageRequest.upload[0], message.assets[0], {
                              headers: {
                                'Content-Type': 'application/octet-stream',
                                'x-goog-acl': 'public-read'
                              }
                            })
                        )
                      }
                    }
                  }
                  setLoadState({...loadState, message: 'done'})
                  return r
                })
                // .then(res => {
                //   setLoadState({...loadState, message: 'done'})
                //   return res
                // })
                .catch(err => {
                  setLoadState({...loadState, message: 'fail'})
                  return err
                })
            )
          }
        }
    
        if (Auth.hasFeature('message') && state.replies.length) {
          setLoadState({...loadState, replies: 'process'})
          requests.push(
            Program.importReplies({programId: state.id, organizationId: props.organizationId, messages: state.replies})
              .then(res => {
                setLoadState({...loadState, replies: 'done'})
                return res
              })
              .catch(err => {
                setLoadState({...loadState, replies: 'fail'})
                return err
              })
          )
        }
    
        if (Auth.hasFeature('subscriber') && state.contacts.length) {
          // only new additions will be sent for update
          const newContacts = state.contacts.filter(c => c.new)
    
          if (newContacts.length) {
            setLoadState({...loadState, subs: 'process'})
            requests.push(
              Program.importSubscribers({programId: state.id, organizationId: props.organizationId, subscribers: newContacts})
                .then(res => {
                  setLoadState({...loadState, subs: 'done'})
                  return res
                })
                .catch(err => {
                  setLoadState({...loadState, subs: 'fail'})
                  return err
                })
            )
          }
        }
    
        requests.push(
          Program.update({...state, organizationId: props.organizationId})
            .then(res => {
              if (res && res.upload && res.upload.length) {                
                postRequests.push(
                  axios
                    .put(res.upload[0], state.programAssets[0], {
                      headers: {
                        'Content-Type': 'application/octet-stream',
                        'x-goog-acl': 'public-read'
                      }
                    })
                )
              }
              setLoadState({...loadState, program: 'done'})
              return res
            })
            .catch(err => {
              setLoadState({...loadState, program: 'fail'})
              return err
            })
        )
    
        return Promise.all(requests)
          .then(res => {

            return Promise.all(postRequests)
              .then(() => {
                setLoading(false)
                setLoadState({program: false, message: false, replies: false, subs: false})

                if (props.onUpdate) {
                  props.onUpdate(res)
                }
              })
          })
          .catch(err => {
            console.error('ProgramForm.onUpdate.failed', err)
            setLoading(false)
            setLoadState({program: false, message: false, replies: false, subs: false})
            const messages = {
              'error_name_must_be_unique': 'A Program with the same name already exists, please enter a different name.',
              'error_keyword_must_be_unique': 'A Program with the same keyword already exists, please enter a different keyword',
              'default': 'Failed to update Program, please try again. If the issue persists, please contact an administrator.'
            }
            
            let message = messages.default
            
            if (err && err.errors) {
              message = messages[err.errors[0].code] || messages.default
            }
            
            setError({ message })
          })
      })
      .catch(err => {
        console.error('ProgramForm.onUpdate.preRequests.failed', err)
        setLoading(false)
        setLoadState({program: false, message: false, replies: false, subs: false})
        const messages = {
          'error_name_must_be_unique': 'A Program with the same name already exists, please enter a different name.',
          'error_keyword_must_be_unique': 'A Program with the same keyword already exists, please enter a different keyword',
          'default': 'Failed to update Program, please try again. If the issue persists, please contact an administrator.'
        }
        
        let message = messages.default
        
        if (err && err.errors) {
          message = messages[err.errors[0].code] || messages.default
        }
        
        setError({ message })
      })
  }

  const onCreate = function () {
    setError(null)
    setLoading(true)

    let keyword = null
    
    // since email programs wont have keyword
    if (state.keyword) {
      keyword = state.keyword.trim() // to get rid of any spaces at end of keyword
    }

    setLoadState({...loadState, program: 'process'})

    console.log('program.create', {...state, keyword, organizationId: props.organizationId,})
    
    // add loading state - program creation / messages writing / replies writing to show client-side
    return Program.create({...state, keyword, organizationId: props.organizationId,})
      .then(res => {
        console.log('ProgramForm.onCreate.then', res)
        let requests = []

        if (res.program && res.program.upload && res.program.upload.length) {
          console.log('ProgramForm.onCreate.uploadImage', res.program.upload[0], state.programAssets)
          requests.push(
            axios
              .put(res.program.upload[0], state.programAssets[0], {
                headers: {
                  'Content-Type': 'application/octet-stream',
                  'x-goog-acl': 'public-read'
                }
              })
          )
        }

        setLoadState({...loadState, program: 'done'})
        // if user entered messages, then write those to db
        if (Auth.hasFeature('message')) {
          if (state.messages.length) {
            setLoadState({...loadState, message: 'process'})
            requests.push(Program.importMessages({programId: res.program.id, organizationId: props.organizationId, messages: state.messages})
              .then(r => {
                if (r.messageIds && r.messageIds.length) {
                  // uses order based on the index value
                  for (let i = 0; i < r.messageIds.length; i++) {
                    const messageRequest = r.messageIds[i]
                    const message = state.messages[messageRequest.index - 1]

                    if (messageRequest.upload && messageRequest.upload.length) {
                      requests.push(
                        axios
                          .put(messageRequest.upload[0], message.assets[0], {
                            headers: {
                              'Content-Type': 'application/octet-stream',
                              'x-goog-acl': 'public-read'
                            }
                          })
                      )
                    }
                  }
                }
                setLoadState({...loadState, message: 'done'})
              }))
          }

          if (state.replies.length) {
            setLoadState({...loadState, replies: 'process'})
            requests.push(
              Program.importReplies({programId: res.program.id, organizationId: props.organizationId, messages: state.replies})
                .then(r => {
                  setLoadState({...loadState, replies: 'done'})
                })
            )
          }
        }

        return Promise.all(requests)
          .then(() => {
            let finalize = []
            if (Auth.hasFeature('subscriber') && state.contacts.length) {
              setLoadState({...loadState, subs: 'process'})
              finalize
                .push(Program.importSubscribers({programId: res.program.id, organizationId: props.organizationId, subscribers: state.contacts}))
            }
            
            Promise.all(finalize)
              .then(() => {
                setLoadState({...loadState, subs: 'done'})
                setLoading(false)
              
                if (props.onCreate) {
                  props.onCreate(res)
                }
              })
          })
        // setLoading(false)
        // redirect to programs or program/:id
      })
      .catch(err => {
        console.error('ProgramForm.onCreate.failed', err)
        setLoading(false)
        setLoadState({program: false, message: false, replies: false, subs: false})
        const messages = {
          'error_name_must_be_unique': 'A Program with the same name already exists, please enter a different name.',
          'error_keyword_must_be_unique': 'A Program with the same keyword already exists, please enter a different keyword',
          'default': 'Failed to create Program, please try again. If the issue persists, please contact an administrator.'
        }
        
        let message = messages.default
        
        if (err && err.errors) {
          message = messages[err.errors[0].code] || messages.default
        }
        
        setError({ message })
      })
  }

  const makeMessage = function () {
    setState({...state, newMessage: {index: state.messages.length + 1, format: state.communication_method === 'sms' ? 'text' : 'html'}})
    // console.log('makeMessage().newMessage', state.newMessage)
    setShowMakeMessage(true)
  }

  const editMessage = function (message) {
    // console.log('ProgramForm.editMessage', message)
    setState({...state, newMessage: {...message, format: state.communication_method === 'sms' ? 'text' : 'html'}})
    // console.log('editMessage().newMessage', state.newMessage)
    setShowEditMessage(true)
  }

  // const makeReply = function () {
  //   setState({...state, newMessage: {index: state.replies.length + 1, type: 'reply'}})
  //   // console.log('makeMessage().newMessage', state.newMessage)
  //   setShowMakeMessage(true)
  // }

  const inviteSubscriber = function () {
    setShowInviteSubscriber(true)
  }

  const importSubscribers = function () {
    setShowImportSubscribers(true)
  }

  const importMessages = function () {
    setShowImportMessages(true)
  }

  const onRemoveSubscriber = function (index) {
    let contacts = [...state.contacts]

    contacts.splice(index, 1)

    setState({...state, contacts})
  }

  const showUpdateSubscriberStatus = (index, updateStatus) => {
    const subscriber = {...state.contacts[index]}

    if (!subscriber || !subscriber.registered) { return }
    
    setUpdateSubscriberStatus({show: true, subscriberIndex: index, subscriberId: subscriber.id, updateStatus})
    setUpdatingSubscribers(true)
  }

  const onCloseUpdateSubscriberStatus = (result) => {
    const updatedContacts = [...state.contacts]
    updatedContacts[updateSubscriberStatus.subscriberIndex].subscribed = result.subscribed
    setState({...state, contacts: updatedContacts})
    setUpdateSubscriberStatus({show: false})
    setUpdatingSubscribers(false)
  }

  const onRemoveReply = function (message) {
    let replies = [...state.replies]
    const index = replies.findIndex(m => m.event === message.event)

    replies.splice(index, 1)

    setState({...state, replies})
  }

  const onRemoveMessage = function (message) {
    let messages = [...state.messages]

    messages.splice(message.index - 1, 1)

    const newMessagesList = []

    for (let i = 0; i < messages.length; i++) {
      const expectedIndex = i + 1
      let msg = messages[i]

      if (msg.index !== expectedIndex) {
        newMessagesList.push({...msg, index: expectedIndex, new: true})
      } else {
        newMessagesList.push(msg)
      }
    }

    setState({...state, messages: newMessagesList})
  }

  const onCloseMakeMessage = function (res) {
    // console.log('MakeMessage.then', res.message)
    if (res.success) {
      if (res.message.type === 'reply') {
        let replies = state.replies
        const indexOfSameEvent = replies.findIndex(r => r.event === res.message.event)
        const reply = {
          options: res.message.options || [],
          body: res.message.body,
          prepend: res.message.prepend,
          excludePrepend: res.message.excludePrepend,
          event: res.message.event,
          type: 'reply'
        }

        // console.log('indexOfSameEvent', indexOfSameEvent, res.message)

        if (indexOfSameEvent >= 0) {
          replies.splice(indexOfSameEvent, 1, reply)
        } else {
          replies.push(reply)
        }

        // console.log('onCloseMakeMessage.replies.then', replies)

        setState({...state, replies})
      }

      if (res.message.type === 'message') {
        let messages = [...state.messages]
        const index = messages.findIndex(m => m.index === res.message.index)

        if (index >= 0) {
          messages.splice(index, 1, {...res.message, new: true})
        } else {
          messages.push({...res.message, new: true})
        }

        // console.log('MakeMessage.updateMessages.then', messages)
        
        setState({...state, messages})
      }
    }

    setShowMakeMessage(false)
    setShowEditMessage(false) // incase the message was edited - separate instance is loaded
  }

  const onCloseInviteSubscriber = function (res) {
    if (res.success) {
      setState({...state, contacts: [].concat(state.contacts, res.subscribers.map(sub => ({...sub, communication_method: state.communication_method, new: true})))})
    }

    setShowInviteSubscriber(false)
  }

  const onCloseImportSubscribers = function (res) {
    // console.log('onCloseImportSubscribers', res)
    if (res.success) {
      let contacts = [...state.contacts]

      res.subscribers.forEach(sub => {
        const {communication_method} = state
        const type = communication_method === 'sms' ? 'phone' : 'email'
        const index = contacts.findIndex(s => s[type] === sub[type])

        if (index >= 0) {
          contacts.splice(index, 1, sub)
        } else {
          contacts.push({...sub, new: true, communication_method})
        }
      })

      setState({...state, contacts})
    }

    setShowImportSubscribers(false)
  }

  const onCloseImportMessages = function (res) {    
    if (res.success) {
      let messages = res.messages.map((msg, i) => {
        const expectedIndex = i + 1
  
        if (msg.index !== expectedIndex) {
          return {...msg, index: expectedIndex, new: true}
        }
  
        return {...msg, new: true}
      })

      // because were importing new csv, delete all current messages to replace with new file
      setDeleteAllMessages(true)
      setState({...state, messages})
    }

    setShowImportMessages(false)
  }

  const formIsValid = function () {
    const {name, keyword, interval, communication_method, surveyId, surveyParticipation} = state

    if (surveyParticipation && !surveyId) {
      return false
    }

    const surveyRequirementsMet = (surveyParticipation && surveyId) || !surveyParticipation

    switch (communication_method) {
      case 'sms':
        return (name && name.length > 2) && (keyword && keyword.length > 2) && (interval) && surveyRequirementsMet
      case 'email':
        return (name && name.length > 2) && interval && surveyRequirementsMet
      default:
        return null
    }
    
  }

  const onCloseForm = function () {
    props.onClose({})
  }

  const onNameChange = function (e) {
    const value = e.target.value
    const replies = updateReplyPrepends(value, state.keyword)

    return setState({ ...state, name: e.target.value, replies })
  }

  const onSurveyChange = function (surveyId) {
    setState({...state, surveyId})
  }

  const toggleSurveyParticipation = function (e) {
    const surveyParticipation = e.target.checked
    const surveyMandatory = (surveyParticipation && state.surveyMandatory) || false
    
    setState({...state, surveyParticipation, surveyMandatory, surveyId: null})
  }

  const onKeywordChange = function (e) {
    // remove newlines/spaces from keyword, must be single word
    const keyword = (e.target.value || '').replace(/\r?\n|\r/g, '')
    const replies = updateReplyPrepends(state.name, keyword)

    return setState({ ...state, keyword, replies })
  }

  const onCommunicationMethodChange = function (e) {
    const communication_method = e.target.value

    
    setState({...state, communication_method, interval: '0 9 * * *', features: getFeatures(communication_method), ...getDefaultMessagesState(), ...getDefaultContactsState(), ...getDefaultRepliesState(communication_method, state.interval)})
  }

  const getFeatures = function (method) {
    let features;

    switch (method) {
      case 'email':
        features = ['message', 'subscriber']
        break
      case 'sms':
        features = ['message', 'subscriber', 'reply']
        break
      default:
        features = []
        break
    }

    // console.log('getFeatures', method, features)

    return features
  }
  
  const updateReplyPrepends = function (name, keyword, replies) {
    return (replies || state.replies)
      .map(reply => {
        let _description = ''
        let instruction = ''

        switch(reply.event) {
          case 'invite':
            _description = `You have been invited${name ? ` to ${name}` : ''}.`

            if (keyword) {
              instruction = `To subscribe to this program, reply with ${(keyword || '').toUpperCase()}.`
            }
            break
          case 'subscribe':
            _description = `You have been subscribed${name ? ` to ${name}` : ''}.`

            if (keyword) {
              instruction = `To unsubscribe from this program, reply with STOP ${(keyword || '').toUpperCase()}.`
            }
            break
          case 'unsubscribe':
            _description = `You have been unsubscribed${name ? ` from ${name}` : ''}.`

            if (keyword) {
              instruction = `To resubscribe, text ${(keyword || '').toUpperCase()}.`
            }
            break
          case 'cancel':
            _description = `You have cancelled the subscription process${name ? ` for ${name}` : ''}.`

            if (keyword) {
              instruction = `If you would like to subscribe to this program, please reply with the keyword${keyword ? `, ${keyword.toUpperCase()}` : ''}.`
            }
            break
          case 'notify_program_questionnaire':
            _description = `In order to subscribe to ${name ? name : 'this program'}, you will need to answer a few questions.`

            if (keyword) {
              instruction = `Please respond with OK to continue. Respond with CANCEL at anytime to stop the process.`
            }
            break
          case 'invalid_answer':
            _description = `Invalid response. Please reply with one of the following options:`
            
            break
          case 'ask_timeofday':
            _description = `What time of day would you like to receive messages? Please reply with one of the following options:`
            
            break
          case 'ask_timezone':
            _description = `What is your timezone? Please reply with one of the following options:`

            break
          default:
            break
        }

        return {...reply, prepend: `${_description}${instruction ? ` ${instruction}` : ''}`}
      })
  }

  const onIntervalChange = (newInterval) => {
    if (newInterval === 'none') {
      // if subscriberSetInterval is set, then consent is required, so that the user can set their own interval
      state.skipInvite = false
    }
    setState({...state, interval: newInterval, subscriberSetInterval: newInterval === 'none'})
  }

  const onSelectImage = function (assets) {
    let image = null
    
    if (assets && assets[0]) {
      const fileNamePieces = assets[0].name.split('.')
      const extension = fileNamePieces[fileNamePieces.length - 1]
      image = {
        filesize: assets[0].size,
        extension
      }
    }

    setState({...state, image, programAssets: assets})
  }

  const onChangeDescription = function (newBody) {
    setState({...state, description: newBody})
  }

  if (initLoad) {
    return null
  }

  return (
    <form>
      <Grid container spacing={1}>
        <Grid item xs={6} style={{padding: '14px'}}>
          <TextField
            fullWidth
            autoFocus
            name='name'
            margin='dense'
            label='Program Name'
            type='text'
            value={state.name}
            disabled={!!state.id}
            onChange={onNameChange}
            helperText="Must be unique and at least 2 characters long"
            required
          />

          {
            state.communication_method !== 'email' && (
              <TextField
                autoFocus
                fullWidth
                name='keyword'
                margin='dense'
                label='Keyword'
                type='text'
                disabled={!!state.id}
                value={state.keyword}
                onChange={onKeywordChange}
                helperText='Must be unique, this will be used to allow customers to subscribe to this Program'
                required
              />
            )
          }

          <br />

          <FormControl fullWidth margin='dense'>
            <InputLabel>Communication Method</InputLabel>
            <Select
              name='communication_method'
              value={state.communication_method}
              onChange={onCommunicationMethodChange}
              disabled={!!state.id}
              required
            >
              {
                state.supportedCommsMethods.map(method => <MenuItem key={method} value={method}>{{'sms': 'SMS', 'email': 'Email'}[method]}</MenuItem>)
              }
            </Select>
            <FormHelperText>How will you deliver messages to your subscribers</FormHelperText>
          </FormControl>

          <br />

          <ProgramTimeScheduler
            editMode={!!state.id}
            communicationMethod={state.communication_method}
            subscriberSetInterval={state.subscriberSetInterval}
            interval={state.interval}
            onChange={onIntervalChange}
            disabled={!!state.id && !Auth.isRole('owner')} />

          {
            state.communication_method === 'email' ? (
              <React.Fragment>
                <br />

                <div>
                  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.isPublic}
                          onChange={(e) => setState({...state, isPublic: e.target.checked})}
                          name="isPublic"
                          disabled={!!state.id && !Auth.isRole('owner')}
                        />
                      }
                      label="Allow public subscription to this program"
                    />
                    <FormHelperText>If enabled, a share link is created to allow subscribing to this program.</FormHelperText>
                  </FormControl>
                </div>
              </React.Fragment>
            ) : null
          }

          {
            state.isPublic ? (
              <React.Fragment>
                <br />
                <PublicProgramLink programId={state.id} />
              </React.Fragment>
            ) : null
          }

          {
            state.communication_method === 'sms' ? (
              <React.Fragment>
                <br />

                <div>
                  {
                    (
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.excludeProgramNameInMessage}
                              onChange={e => setState({...state, excludeProgramNameInMessage: e.target.checked})}
                              name="excludeProgramNameInMessage"
                              disabled={!!state.id && !Auth.isRole('owner')}
                            />
                          }
                          label="Exclude the Program name from message body"
                        />
                        <FormHelperText>Check this if you don't want program name included as part of the message</FormHelperText>
                      </FormControl>
                    )
                  }
                </div>
              </React.Fragment>
            ) : null
          }

          <br />

          <div>
            {
              !state.id && (
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.surveyParticipation}
                        onChange={toggleSurveyParticipation}
                        name="surveyParticipation"
                      />
                    }
                    label="Participate in surveys"
                  />
                  <FormHelperText>If enabled, all subscribers of this program will be automatically enrolled in the selected survey for participation.</FormHelperText>
                </FormControl>
              )
            }
          </div>

          <div>
            {
              (
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.surveyMandatory}
                        onChange={e => setState({...state, surveyMandatory: e.target.checked})}
                        name="surveyParticipation"
                        disabled={(!!state.id && !Auth.isRole('owner')) || !state.surveyParticipation}
                      />
                    }
                    label="Require completion of initial survey"
                  />
                  <FormHelperText>Require initial survey completion befor receiving Program messages.</FormHelperText>
                </FormControl>
              )
            }
          </div>
            
          <div>
            {
              state.communication_method === 'sms' && (
                <FormControl margin='dense'>
                  <FormControlLabel
                    control={<Checkbox disabled={(!!state.id && !Auth.isRole('owner')) || state.interval === 'none'} checked={!state.skipInvite} onChange={(e) => setState({...state, skipInvite: !e.target.checked})} name="skipInvite" />}
                    label="Require consent"
                  />
                  <FormHelperText>This will require user to reply to invitation before they are subscribed.</FormHelperText>
                </FormControl>
              )
            }
          </div>

          <br />

          {!state.id && state.surveyParticipation && <SurveySelector organizationId={props.organizationId} surveyId={state.surveyId} onChange={onSurveyChange} />}

          {state.id && state.surveyId && (
            <SurveySelector organizationId={props.organizationId} surveyId={state.surveyId} onChange={onSurveyChange} disabled={true} />
          )}
          
        </Grid>

        {
          state.communication_method === 'email' ? (
            <Grid item xs={6} style={{padding: '14px'}}>
              <PublicFieldsProgramForm description={state.description} onChangeDescription={onChangeDescription} assets={state.programAssets} onSelectImage={onSelectImage} imageSrc={state.imageSrc} />
            </Grid>
          ) : null
        }
      </Grid>

      <br />
      <br />

      <Grid container spacing={3}>
        {
          Auth.hasFeature('message') && state.features.includes('message') && (
            <Grid item xs={6}>
              <Typography>
                Messages 
                <IconButton onClick={makeMessage} disabled={!Auth.hasFeature('message')}>
                  <MakeIcon />
                </IconButton>
                <IconButton onClick={importMessages} disabled={!Auth.hasFeature('message')} >
                  <ImportIcon />
                </IconButton>
              </Typography>
              {
                state.communication_method === 'sms' && (
                  <MessagesList
                    messages={state.messages}
                    onSelect={editMessage}
                    onRemove={onRemoveMessage}
                    readOnly={!Auth.hasFeature('message')}
                    removable={true}
                  />
                )
              }

              {
                state.communication_method === 'email' && (
                  <EmailMessagesList
                    messages={state.messages}
                    onSelect={editMessage}
                    onRemove={onRemoveMessage}
                    readOnly={!Auth.hasFeature('message')}
                    removable={true}
                  />
                )
              }
            </Grid>
          )
        }

        {
          Auth.hasFeature('message') && state.features.includes('reply') && (
            <Grid item xs={6}>
              <Typography>Replies</Typography>
              <MessagesList
                messages={state.replies}
                onSelect={editMessage}
                onRemove={onRemoveReply}
                readOnly={!Auth.hasFeature('message')}
                removable={false}
              />
            </Grid>
          )
        }

        {
          Auth.hasFeature('subscriber') && state.features.includes('subscriber') && (
            <Grid item xs={6}>
              <Typography>
                Contacts 
                <IconButton onClick={inviteSubscriber} disabled={!Auth.hasFeature('subscriber') || !state.active || state.disabled}>
                  <MakeIcon />
                </IconButton>
                <IconButton onClick={importSubscribers} disabled={!Auth.hasFeature('subscriber') || !state.active || state.disabled}>
                  <ImportIcon />
                </IconButton>
              </Typography>
              {/* <MessageList messages={state.contacts} /> */}
              <SubscribersList
                subscribers={state.contacts}
                disableActions={updatingSubscribers}
                onRemove={onRemoveSubscriber}
                onUnsubscribe={(index) => showUpdateSubscriberStatus(index, 'unsubscribe')}
                onSubscribe={(index) => showUpdateSubscriberStatus(index, 'subscribe')} />
            </Grid>
          )
        }
      </Grid>

      <br />

      {
        error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        )
      }

      {showMakeMessage && (
        <MakeMessage
          communicationMethod={state.communication_method}
          countryCode={props.countryCode}
          open={showMakeMessage}
          replies={state.replies}
          message={state.newMessage}
          onClose={onCloseMakeMessage}
        />
      )}
      {showEditMessage && (
        <MakeMessage
          communicationMethod={state.communication_method}
          countryCode={props.countryCode}
          open={showEditMessage}
          replies={state.replies}
          message={state.newMessage}
          editMode={true}
          onClose={onCloseMakeMessage}
        />
      )}
      {showInviteSubscriber && <InviteSubscriber open={showInviteSubscriber} onClose={onCloseInviteSubscriber} countryCode={props.countryCode} communicationMethod={state.communication_method} />}
      {showImportSubscribers && <ImportSubscribers open={showImportSubscribers} onClose={onCloseImportSubscribers} countryCode={props.countryCode} communicationMethod={state.communication_method} />}
      {showImportMessages && <ImportMessages communicationMethod={state.communication_method} open={showImportMessages} onClose={onCloseImportMessages} />}
      {loading && <LoadingDialog update={state.id} load={loadState} />}
      
      {
        updateSubscriberStatus.show && (
          <ChangeSubscriberSubscriptionStatus
            programId={state.id}
            organizationId={props.organizationId}
            subscriberId={updateSubscriberStatus.subscriberId}
            request={updateSubscriberStatus.updateStatus}
            onClose={onCloseUpdateSubscriberStatus}
          />
        )
      }

      {
        state.id && state.subscriberSetInterval && (
          <SubscribersTable subscribers={state.contacts} />
        )
      }

      <div style={{marginTop: '2rem'}}>
        <Button onClick={onCloseForm} color="secondary" disabled={loading}>Cancel</Button>
        {state.id && <Button onClick={onUpdate} color="primary" disabled={!formIsValid() || loading}>Update</Button>}
        {!state.id && <Button onClick={onCreate} color="primary" disabled={!formIsValid() || loading}>Create</Button>}
      </div>

    </form>
  )
}
