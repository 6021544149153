import React, {Fragment, useEffect} from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import {MuiPickersUtilsProvider, ClockView} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const getInitTime = () => {
  let date = new Date()

  return date
}

const cronToTime = (cronStr) => {
  // assumes only hours change
  let parts = cronStr.split(' ')
  let hours = parseInt(parts[1])
  let date = new Date()

  date.setMinutes(0)
  date.setMilliseconds(0)
  date.setHours(hours)

  return date
}

const timeToCron = (time) => {
  let date = new Date(time)
  let hours = date.getHours()

  // only hours is dynamic
  return `0 ${hours} * * *`
}

export default function ProgramTimeScheduler (props) {
  const [selectedOption, setSelectedOption] = React.useState('0 9 * * *')
  const [dateValue, setDateValue] = React.useState(getInitTime())

  const onChange = (newValue) => {
    if (newValue !== 'custom' || newValue === 'none') {
      props.onChange(newValue)
    } else {
      let _interval = timeToCron(dateValue)
      props.onChange(_interval)
    }
  }

  useEffect(() => {
    const options = ['0 9 * * *', '0 16 * * *', '0 17 * * *', '0 9,17 * * *', '0 8-18/2 * * *', 'none']
    let _selectedOption = selectedOption !== 'custom' ? options.find(opt => opt === props.interval) : 'custom'

    if (!_selectedOption) {
      _selectedOption = 'custom'
    }

    setSelectedOption(_selectedOption)

    if (_selectedOption === 'custom') {
      let _date = cronToTime(props.interval)
      setDateValue(_date)
    }
  }, [props.interval])

  return (
    <Fragment>
      <FormControl fullWidth margin='dense' style={{border: '1px solid #ccccc'}}>
        <InputLabel>Time of Day</InputLabel>

        <Select
          name='interval'
          value={selectedOption}
          onChange={(e) => {
            setSelectedOption(e.target.value)
            onChange(e.target.value)
          }}
          required
          disabled={props.disabled}
        >
          {/* we can't change between letting subscribers choose their time and the Program setting the time, so on edits don't let them change this setting */}
          <MenuItem disabled={(props.editMode && props.interval !== 'none') || props.communicationMethod !== 'sms'} value={'none'}>Let my subscribers pick their time</MenuItem>
          <MenuItem disabled={props.editMode && props.interval === 'none'} value={'0 9 * * *'}>Every Morning (9AM MST)</MenuItem>
          <MenuItem disabled={props.editMode && props.interval === 'none'} value={'0 16 * * *'}>Every 4PM MST</MenuItem>
          <MenuItem disabled={props.editMode && props.interval === 'none'} value={'0 17 * * *'}>Every Evening (5PM MST)</MenuItem>
          <MenuItem disabled={props.editMode && props.interval === 'none'} value={'0 9,17 * * *'}>Every Morning and Evening (9AM & 5PM MST)</MenuItem>
          <MenuItem disabled={props.editMode && props.interval === 'none'} value={'0 8-18/2 * * *'}>Every 2 Hours (Between 8AM and 6PM)</MenuItem>
          <MenuItem disabled={props.editMode && props.interval === 'none'} value={'custom'}>Custom</MenuItem>
        </Select>

        {
          selectedOption === 'custom' ? (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ClockView // or just directly use components
                type="hours"
                date={dateValue}
                ampm={false}
                onHourChange={(_time) => {
                  setDateValue(_time)
                  onChange(timeToCron(_time))
                }}
              />
            </MuiPickersUtilsProvider>
          ) : null
        }
        <FormHelperText>When should we send out messages to your subscribers</FormHelperText>
        <FormHelperText><strong>Please Note:</strong> The time of day is in Mountain Standard Time (MST) and displayed as a 24-hour clock.</FormHelperText>
      </FormControl>
    </Fragment>
  )
}